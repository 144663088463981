import { forwardRef } from 'react';
import { classNames } from '~/utils/styles';
import type { ChipProps } from './Chip.types';

export const Chip = forwardRef<HTMLSpanElement, ChipProps>(
  ({ className, size = 'medium', appearance = 'default', children }, ref) => (
    <span
      ref={ref}
      className={classNames(
        'w-auto whitespace-nowrap rounded-full',
        {
          'label-200 px-[10px] py-1': size === 'small',
          'label-100 px-3 py-[6px]': size === 'medium',
        },
        {
          'bg-neutral-100 text-neutral-1000': appearance === 'default',
          'bg-gradient-100/[.1] text-gradient-100': appearance === 'info',
          'bg-green-100 text-green-700': appearance === 'success',
          'bg-yellow-100 text-yellow-900': appearance === 'warning',
          'bg-red-100 text-red-700': appearance === 'danger',
          'bg-technology-nuclear/[.1] text-neutral-1000': appearance === 'other',
        },
        className,
      )}
    >
      {children}
    </span>
  ),
);
