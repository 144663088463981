import { useState } from 'react';
import { Popover } from '~/components/granular';
import { classNames } from '~/utils/styles';
import type { TooltipProps } from './Tooltip.types';

export const Tooltip = ({
  align = 'center',
  children,
  className,
  direction = 'bottom',
  large = false,
  open,
  trigger,
  openOnHover,
  disabled,
  ...props
}: TooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleTriggerClickToggle = () => {
    if (open !== undefined || disabled) {
      return;
    }
    setIsOpen((prev) => !prev);
  };

  const handleHoverToggle = (isHovering: boolean) => {
    if (!openOnHover || open !== undefined || disabled) {
      return;
    }
    setIsOpen(isHovering);
  };

  const classes = classNames(
    className,
    '[&_span]:h-1', // fixes tooltip arrow blurriness when rotated (left, right)
    'body-200-light !bg-neutral-1000 max-w-[300px] rounded-[4px] px-2 py-2 text-white',
    {
      'min-w-[200px]': large,
    },
  );

  return (
    <div onMouseEnter={() => handleHoverToggle(true)} onMouseLeave={() => handleHoverToggle(false)}>
      <Popover
        align={align}
        className={classes}
        direction={direction}
        open={open !== undefined ? open : isOpen}
        onTriggerClick={handleTriggerClickToggle}
        sideOffset={2}
        trigger={trigger}
        withArrow
        disabled={disabled}
        {...props}
      >
        <div className="p-1">{children}</div>
      </Popover>
    </div>
  );
};
