import { format } from 'date-fns';
import { forwardRef, useMemo, useState } from 'react';
import { DatePicker, Input, Popover } from '~/components/granular';
import { DATE_FORMATS, classNames } from '~/utils';

import type { DatePickerInputProps } from './DatePickerInput.props';

export const DatePickerInput = forwardRef<HTMLDivElement, DatePickerInputProps>(
  (
    {
      className,
      id,
      hasError,
      isDisabled,
      leftIcon,
      minDate,
      name,
      placeholder,
      selectsRange,
      showMonthYearPicker,
      dateFormat = DATE_FORMATS.DATE,
      onChange,
    },
    forwardedRef,
  ) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date | [Date | null, Date | null] | null>(null);

    const startDate = useMemo(() => {
      if (Array.isArray(selectedDate)) {
        return selectedDate[0];
      }
      return selectedDate;
    }, [selectedDate]);

    const endDate = useMemo(() => {
      if (Array.isArray(selectedDate)) {
        return selectedDate[1];
      }
      return null;
    }, [selectedDate]);

    const inputValue = useMemo(() => {
      if (!selectedDate) {
        return '';
      }

      if (Array.isArray(selectedDate)) {
        const startDate = selectedDate[0] ? format(selectedDate[0], dateFormat) : '';
        const endDate = selectedDate[1] ? format(selectedDate[1], dateFormat) : '';
        return `${startDate} - ${endDate}`;
      }

      return format(selectedDate, dateFormat);
    }, [selectedDate, dateFormat]);

    function handleOnChange(date: Date | [Date | null, Date | null] | null) {
      setSelectedDate(date);
      onChange?.(date);

      if (selectsRange && Array.isArray(date) && date.some((d) => d === null)) {
        return;
      }

      setPopoverOpen(false);
    }

    const inputClasses = classNames(
      'cursor-pointer bg-white text-left',
      'invalid:hover:outline invalid:hover:outline-[1px] invalid:hover:outline-red-800 invalid:focus:outline-red-800',
      {
        '!border-red-800 !outline-red-800': hasError,
        'hover:cursor-not-allowed hover:border-neutral-200 disabled:border-neutral-200 disabled:bg-white disabled:text-neutral-400 placeholder:disabled:text-neutral-400':
          isDisabled,
      },
    );
    const containerClasses = classNames('w-full outline-none', {
      '[&>div>svg]:hover:text-teal-800': !hasError && !isDisabled,
      'focus:[&>div>svg]:text-teal-800 active:[&>div>svg]:text-teal-800': !hasError,
      '[&>div>svg]:text-red-800': hasError && !isDisabled,
      className,
    });

    return (
      <Popover
        ref={forwardedRef}
        open={popoverOpen}
        onTriggerClick={(open) => setPopoverOpen(open)}
        trigger={
          <span className={containerClasses}>
            <Input
              id={id}
              className={inputClasses}
              hasError={hasError}
              name={name}
              leftIcon={leftIcon}
              rightIcon="ArrowDownSIcon"
              type="text"
              readOnly
              value={inputValue}
              autoComplete="off"
              placeholder={placeholder}
            />
          </span>
        }
      >
        <div className="px-4 py-3">
          <DatePicker
            minDate={minDate}
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={(date) => handleOnChange(date)}
            selectsRange={selectsRange}
            showMonthYearPicker={showMonthYearPicker}
          />
        </div>
      </Popover>
    );
  },
);
