import * as React from 'react';
import type { SVGIconProps } from './Icon.types';

const RemoteControlIcon = React.forwardRef<SVGSVGElement, SVGIconProps>(
  ({ color = 'currentColor', filled, ...props }, forwardedRef) => {
    return (
      <svg
        ref={forwardedRef}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>{props.title}</title>
        <path
          d="M18 2C18.2652 2 18.5196 2.10536 18.7071 2.29289C18.8946 2.48043 19 2.73478 19 3V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8946 18.2652 22 18 22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V3C5 2.73478 5.10536 2.48043 5.29289 2.29289C5.48043 2.10536 5.73478 2 6 2H18ZM17 4H7V20H17V4ZM15 15V17H13V15H15ZM11 15V17H9V15H11ZM13 6V8H15V10H12.999L13 12H11L10.999 10H9V8H11V6H13Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default RemoteControlIcon;
