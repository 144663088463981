const FR: Record<string, string> = {
  to: 'à',
  of: 'sur',
  page: '',
  pageSizeSelectorLabel: 'Lignes par page :',

  // ARIA
  ariaPageSizeSelectorLabel: 'Lignes par page',
};

export default FR;
