import { forwardRef, useEffect, useState } from 'react';
import { Button } from '~/components/granular';
import { classNames } from '~/utils/styles';

import type { ToggleGroupItem, ToggleGroupProps } from './ToggleGroup.types';

export const ToggleGroup = forwardRef<HTMLFieldSetElement, ToggleGroupProps>(
  ({ className, disabled, items, size = 'large', value, onValueChange, ...props }, forwardedRef) => {
    const [activeIndex, setActiveIndex] = useState<number>(items.findIndex((item) => getValue(item) === value));

    useEffect(() => {
      setActiveIndex(items.findIndex((item) => getValue(item) === getValue(value)));
    }, [value, items]);

    const classes = classNames('flex', className);

    function getValue(item: string | ToggleGroupItem | undefined) {
      return typeof item === 'object' ? item.value : item;
    }
    function getLabel(item: string | ToggleGroupItem) {
      return typeof item === 'object' ? item.label : item;
    }

    return (
      <fieldset ref={forwardedRef} className={classes} {...props}>
        {items.map((item, index) => {
          const btnClasses = classNames('!justify-center !outline-none relative mr-[-1px] text-neutral-800', {
            'rounded-l': index === 0,
            'rounded-r': index === items.length - 1,
            'label-200 px-4 py-3 text-[13px]': size === 'small',
            'label-100 min-w-[112px] px-6 py-3': size === 'large',
            '!bg-teal-200 !text-teal-700': index === activeIndex,
            'hover:!bg-teal-100': index === activeIndex && !disabled,
            '!bg-white !text-neutral-500': index !== activeIndex && disabled,
            'hover:!bg-neutral-100 hover:!text-neutral-800': index !== activeIndex && !disabled,
            'hover:z-10 hover:border-teal-700': !disabled,
            'focus:!border-neutral-300 focus:!bg-teal-100 focus:!text-teal-700 active:!bg-teal-100 active:!text-teal-700':
              !disabled,
            'border border-neutral-300 border-solid': disabled,
          });

          const onClick = () => {
            onValueChange?.(items[index]);
            setActiveIndex(index);
          };

          return (
            <Button
              key={getValue(item)}
              appearance="secondary"
              className={btnClasses}
              disabled={disabled}
              onClick={onClick}
              autoFocus={index === activeIndex}
              size={size}
            >
              {getLabel(item)}
            </Button>
          );
        })}
      </fieldset>
    );
  },
);
