const DE: Record<string, string> = {
  to: 'zu',
  of: 'von',
  page: '',
  pageSizeSelectorLabel: 'Zeilen pro Seite:',

  // ARIA
  ariaPageSizeSelectorLabel: 'Zeilen pro Seite',
};

export default DE;
