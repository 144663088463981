import * as RadixDialog from '@radix-ui/react-dialog';
import { forwardRef } from 'react';
import { type ButtonAction, ButtonActions, Icon, IconBadge, Link, Markdown } from '~/components/granular';
import { classNames } from '~/utils/styles';
import { GradientScroller } from '../gradient-scroller/GradientScroller';
import type { DialogProps } from './Dialog.types';

export const Dialog = forwardRef<HTMLDivElement, DialogProps>(
  (
    {
      actions,
      children,
      className = '',
      icon,
      iconAppearance,
      linkAction,
      markdown,
      open,
      preventOutsideClickClose,
      side,
      title,
      trigger,
      width = 'medium',
      onOpenChange,
      ...props
    },
    forwardedRef,
  ) => (
    <RadixDialog.Root open={open} onOpenChange={onOpenChange}>
      {trigger ? <RadixDialog.Trigger asChild>{trigger}</RadixDialog.Trigger> : null}
      <RadixDialog.Portal>
        <RadixDialog.Overlay
          className={classNames('fixed inset-0 z-20 flex bg-black bg-opacity-50', {
            'data-[state=open]:animate-slideLeftAndFade': side === 'right',
            'data-[state=open]:animate-slideRightAndFade': side === 'left',
            'data-[state=open]:animate-overlayShow': !side,
          })}
        >
          <RadixDialog.Content
            ref={forwardedRef}
            className={classNames(
              'm-auto flex max-h-[85vh] w-full flex-col overflow-hidden rounded-[6px] bg-white shadow-xl focus:outline-none',
              {
                'max-w-[300px]': width === 'small' && !side,
                'max-w-[480px]': width === 'medium' && !side,
                'max-w-[920px]': width === 'large' && !side,
                'max-w-[calc(100%-10rem)]': width === 'full',
                // side opening
                'data-[state=open]:animate-contentShow': !side,
                'mt-0 h-screen max-h-screen w-1/2 rounded-none': side === 'left' || side === 'right',
                'mr-0 data-[state=open]:animate-slideLeftAndFade': side === 'right',
                'ml-0 data-[state=open]:animate-slideRightAndFade': side === 'left',
              },
              className,
            )}
            onPointerDownOutside={(event) => preventOutsideClickClose && event.preventDefault()}
            aria-describedby={undefined}
            {...props}
          >
            <div
              className={classNames('flex items-center border-solid px-6 py-4', {
                'border-neutral-200 border-b': !side,
              })}
            >
              {icon && <IconBadge icon={icon} appearance={iconAppearance} size="small" className="mr-3" />}
              <RadixDialog.Title className="title-200 text-neutral-1000" data-testid="dialog-heading">
                {title}
              </RadixDialog.Title>
              <RadixDialog.Close asChild className="ml-auto">
                <button type="button" aria-label="Close">
                  <Icon icon="CloseIcon" width={24} height={24} className="text-neutral-1000" />
                </button>
              </RadixDialog.Close>
            </div>

            <GradientScroller
              className={classNames('flex min-h-0 shrink-1 flex-col', {
                'flex-grow [&>div]:flex [&>div]:flex-col': !!side,
              })}
            >
              <div
                className={classNames('body-200-light px-6 text-neutral-800', {
                  'flex-grow pb-8': !!side,
                  'py-5': !side,
                })}
              >
                {markdown && <Markdown markdown={markdown} />}
                {children}
              </div>

              {(actions || linkAction) && (
                <div
                  className={classNames('flex justify-end px-6 pt-5 pb-6', {
                    'sticky bottom-0 w-full border-t border-t-neutral-300 bg-white': !!side,
                  })}
                  data-testid="dialog-actions"
                >
                  {linkAction ? (
                    <Link
                      key={linkAction.id}
                      leftIcon={linkAction.leftIcon}
                      rightIcon={linkAction.rightIcon}
                      href={linkAction.href}
                      target={linkAction.target}
                    >
                      {linkAction.children}
                    </Link>
                  ) : (
                    <ButtonActions actions={actions as Array<ButtonAction>} />
                  )}
                </div>
              )}
            </GradientScroller>
          </RadixDialog.Content>
        </RadixDialog.Overlay>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  ),
);
