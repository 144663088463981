const ja: Record<string, string> = {
  to: 'to',
  of: 'of',
  page: '',
  pageSizeSelectorLabel: 'Rows per page:',

  // ARIA
  ariaPageSizeSelectorLabel: 'Rows per page:',
};

export default ja;
