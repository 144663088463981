const IT: Record<string, string> = {
  to: 'a',
  of: 'di',
  page: '',
  pageSizeSelectorLabel: 'Righe per pagina:',

  // ARIA
  ariaPageSizeSelectorLabel: 'Righe per pagina',
};

export default IT;
