import { useCallback, useEffect, useState } from 'react';
import type { SelectItem } from '~/components/granular';
import { useUrlParams } from '../useUrlParams';

interface FilterHandlers<Response> {
  filterKey: string;
  isLoading?: boolean;
  mapSelectedItems?: (selectedGroups: Array<string>) => Response;
}

export function useFilterHandlers<Response>({ filterKey, isLoading, mapSelectedItems }: FilterHandlers<Response>) {
  const { searchParams, location, setUrlParams, getAllHashValuesByKey, deleteHashKey } = useUrlParams();

  const [selectedFilters, setSelectedFilters] = useState<Response>();

  useEffect(() => {
    if (isLoading || !mapSelectedItems) {
      return;
    }

    const filterValues = getAllHashValuesByKey(filterKey) ?? [];
    const selectedItems = mapSelectedItems(filterValues);

    setSelectedFilters(selectedItems);
  }, [filterKey, isLoading, getAllHashValuesByKey, mapSelectedItems]);

  const selectedTagsToUrlHash = useCallback(
    (key: string, items: Array<SelectItem>) => {
      const locationHash = new URLSearchParams(location.hash.slice(1));

      const existingValues = locationHash.getAll(key);
      const newValues = items.map((child) => child.value);

      const valuesToAdd = newValues.filter((value) => !existingValues.includes(value));
      const valuesToDelete = existingValues.filter((value) => !newValues.includes(value));

      for (const value of valuesToAdd) {
        locationHash.append(key, value);
      }

      for (const value of valuesToDelete) {
        locationHash.delete(key, value);
      }

      setUrlParams(searchParams, locationHash);
    },
    [location, searchParams, setUrlParams],
  );

  const selectedStringToUrlHash = useCallback(
    (key: string, item: string) => {
      const locationHash = new URLSearchParams(location.hash.slice(1));
      locationHash.set(key, item);

      setUrlParams(searchParams, locationHash);
    },
    [location, searchParams, setUrlParams],
  );

  const selectedStringsToUrlHash = useCallback(
    (selectedStrings: Array<[string, string]>) => {
      const locationHash = new URLSearchParams(location.hash.slice(1));

      for (const [k, s] of selectedStrings) {
        locationHash.set(k, s);
      }

      setUrlParams(searchParams, locationHash);
    },
    [location, searchParams, setUrlParams],
  );

  const clearSelectedFilter = deleteHashKey;

  return {
    selectedTagsToUrlHash,
    selectedStringToUrlHash,
    selectedStringsToUrlHash,
    clearSelectedFilter,
    selectedFilters,
  };
}
