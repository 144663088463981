const demandColor = '#0F4974';
const supplyColor = '#08875D';

export const colors = {
  inherit: 'inherit',
  current: 'currentColor',
  transparent: 'transparent',
  black: '#000',
  white: '#fff',
  neutral: {
    '100': '#FBFAF9',
    '200': '#F2EFED',
    '300': '#E3E0DD',
    '400': '#D2CECB',
    '500': '#B8B2AD',
    '600': '#897F78',
    '700': '#6C6560',
    '800': '#56514E',
    '900': '#3D3A38',
    '1000': '#2A2929',
  },
  teal: {
    '100': '#F2F9F8',
    '200': '#E8F4F3',
    '500': '#94D1CC',
    '700': '#00988B',
    '800': '#03857A',
    '900': '#06756C',
  },
  green: {
    '100': '#F1F9F6',
    '700': '#08875D',
    '800': '#04724D',
    '900': '#066042',
  },
  yellow: {
    '100': '#FFF8EB',
    '700': '#B25E09',
    '800': '#96530F',
    '900': '#80460D',
  },
  red: {
    '100': '#FFF6F7',
    '200': '#FAEBEC',
    '700': '#E02D3C',
    '800': '#BA2532',
    '900': '#981B25',
  },
  supplyGradient: {
    '100': '#a5db36',
    '200': '#7ad151',
    '300': '#54c568',
    '400': '#35b779',
    '500': '#22a884',
    '600': '#1f988b',
    '700': '#23888e',
    '800': '#2a788e',
    '900': '#31688e',
    '1000': '#39568c',
  },
  demandGradient: {
    '100': '#297b8e',
    '200': '#23898e',
    '300': '#1f978b',
    '400': '#21a585',
    '500': '#2eb37c',
    '600': '#46c06f',
    '700': '#65cb5e',
    '800': '#89d548',
    '900': '#b0dd2f',
    '1000': '#d8e219',
  },
  technology: {
    anyRenewable: '#8195A9',
    battery: '#CBC0E9',
    biodegradable: '#B9E198',
    biogas: '#F6AFAF',
    biomass: '#57B06B',
    coal: '#BAD9F6',
    gas: '#E86571',
    geoThermal: '#F3A45B',
    hydro: '#6EA1DD',
    landfillGas: '#F398CD',
    marine: '#BAD9F6',
    nuclear: '#AB80CC',
    offshoreWind: '#A6E5CF',
    onshoreWind: '#58CAA1',
    sewageGas: '#FBCEE6',
    solar: '#FDCF77',
    unknown: '#B3BFC9',
    wind: '#81D8B9',
  },
  chart: {
    crosshair: '#0f4974',

    long: supplyColor,
    short: demandColor,

    demand: demandColor,
    excesses: '#1fb084',
    excessesBackground: '#b9dfdc',
    matched: '#007f8a',
    other: '#F2EFED',
    production: '#92cf64',
    sankey: {
      linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
      stops: [
        [0, supplyColor],
        [1, demandColor],
      ],
    },
    stripedBackground: '#cae8df',
    supply: supplyColor,
    unallocated: '#CEE7DF',
    unallocatedBackground: '#fafafa',
    unmatched: '#CFDBE3',
    unmatchedBackground: '#fafafa',
  },
} as const;

export const pattern = {
  stroke45Deg: 'M 0 8 L 8 0 M -0.8 0.8 L 0.8 -0.8 M 7.2 8.8 L 8.8 7.2', // Size 8
  size: 8,
  strokeWidth: 3,
} as const;
