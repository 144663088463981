import type { GridReadyEvent } from 'ag-grid-community';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { memo, useRef } from 'react';
import { classNames } from '~/utils/styles';

import { useIntl } from 'react-intl';
import { useGridApiContext } from '~/contexts/grid-api';
import { useGridApi } from '~/hooks/useGridApi';
import { useLocales } from '~/intl';
import type { DataGridProps } from './DataGrid.types';
import * as dataGridLocales from './data-grid-locales';

const baseDefaultColDef = {
  suppressHeaderMenuButton: true,
  flex: 1,
};

export const getContextMenuItems = () => ['copy', 'copyWithHeaders', 'copyWithGroupHeaders'];

function DataGridBase<T>({
  className,
  rowData,
  domLayout = 'autoHeight',
  colDefs,
  defaultColDef,
  gridId = 'default',
  overlayNoRowsTemplate,
  paginationPageSize = 15,
  paginationPageSizeSelector = [10, 15, 30, 50],
  rowModelType,
  masterDetail = true,
  pagination = true,
  onGridReady,
  getRowId,
  ...props
}: DataGridProps<T>) {
  const { formatMessage } = useIntl();
  const { addGridApi, removeGridApi } = useGridApiContext();
  const gridApi = useGridApi(gridId);
  const dataGridContainerRef = useRef<HTMLDivElement>(null);

  const { locale } = useLocales();

  const handleGridReady = (event: GridReadyEvent) => {
    addGridApi(gridId, event.api);
    onGridReady?.(event);
  };

  const handleGridPreDestroyed = () => {
    removeGridApi(gridId);
  };

  /**
   * Workaround to show the actual number of rows for a given page
   * Issue: https://github.com/ag-grid/ag-grid/issues/4295
   */
  const setLastRowOnPageElement = () => {
    if (!gridApi || !dataGridContainerRef?.current || gridApi.isDestroyed()) {
      return;
    }

    const lastRowOnPageElement = dataGridContainerRef.current.querySelector(`[ref=\'lbLastRowOnPage\']`);
    const isLastPage = gridApi.paginationGetTotalPages() === gridApi.paginationGetCurrentPage() + 1;
    const endRow = isLastPage
      ? gridApi.paginationGetRowCount()
      : (gridApi.paginationGetCurrentPage() + 1) * gridApi.paginationGetPageSize();

    if (lastRowOnPageElement) {
      lastRowOnPageElement.innerHTML = endRow.toString();
    }
  };

  return (
    <div
      ref={dataGridContainerRef}
      className={classNames('ag-theme-quartz', className)}
      style={{ width: '100%', height: '100%' }}
      data-testid="data-grid"
    >
      <AgGridReact
        suppressDragLeaveHidesColumns
        cellSelection
        columnDefs={colDefs}
        getContextMenuItems={getContextMenuItems}
        defaultColDef={{ ...baseDefaultColDef, ...defaultColDef }}
        rowData={rowData}
        domLayout={domLayout}
        rowModelType={rowModelType}
        pagination={pagination}
        masterDetail={masterDetail}
        paginationPageSize={paginationPageSize}
        paginationPageSizeSelector={paginationPageSizeSelector}
        onGridReady={handleGridReady}
        onGridPreDestroyed={handleGridPreDestroyed}
        onPaginationChanged={setLastRowOnPageElement}
        getRowId={getRowId}
        localeText={dataGridLocales[locale]}
        overlayNoRowsTemplate={overlayNoRowsTemplate || formatMessage({ id: 'errors.noResults' })}
        {...props}
      />
    </div>
  );
}

export const DataGrid = memo(DataGridBase) as typeof DataGridBase;
