const zhCN: Record<string, string> = {
  to: '到',
  of: '共',
  page: '',
  pageSizeSelectorLabel: '每页数目:',

  // ARIA
  ariaPageSizeSelectorLabel: '每页数目',
};

export default zhCN;
