import * as RadixSelect from '@radix-ui/react-select';
import { forwardRef } from 'react';
import { Input } from '~/components/granular';
import { classNames } from '~/utils/styles';
import type { DropdownTriggerProps } from './DropdownTrigger.types';

export const DropdownTrigger = forwardRef<HTMLDivElement, DropdownTriggerProps>(
  ({ customTrigger, fixedLabel, hasError, isDisabled, isOpen, id, placeholder, value, ...props }, forwardedRef) => {
    const TriggerElement = customTrigger || Input;

    const inputClasses = classNames(
      'cursor-pointer bg-white text-left',
      'invalid:hover:outline invalid:hover:outline-[1px] invalid:hover:outline-red-800 invalid:focus:outline-red-800',
      {
        '!border-red-800 !outline-red-800': hasError,
        'hover:cursor-not-allowed hover:border-neutral-200 disabled:border-neutral-200 disabled:bg-white disabled:text-neutral-400 placeholder:disabled:text-neutral-400':
          isDisabled && !!customTrigger,
        'border-teal-800 outline-teal-800': isOpen && !hasError,
        'outline outline-1': isOpen,
      },
    );
    const triggerContainerClasses = classNames('w-full outline-none', {
      '[&>div>svg]:hover:text-teal-800': !hasError && !isDisabled,
      '[&>div>svg]:text-teal-800': isOpen && !hasError,
      '[&>div>svg]:text-red-800': hasError && !isDisabled,
    });

    return (
      <RadixSelect.Trigger className={triggerContainerClasses}>
        <TriggerElement
          id={id}
          ref={forwardedRef}
          className={inputClasses}
          disabled={isDisabled}
          hasError={hasError}
          placeholder={placeholder}
          rightIcon="ArrowDownSIcon"
          value={fixedLabel ? fixedLabel : value}
          role="textbox"
          onChange={(e: Event) => e.preventDefault()}
          {...props}
        />
      </RadixSelect.Trigger>
    );
  },
);
