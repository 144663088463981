import { useCallback, useEffect, useRef } from 'react';

const errorCallback = () => {
  throw new Error('Cannot call an event handler while rendering.');
};

// https://legacy.reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback

export function useStableCallback<Args extends unknown[], ReturnValue>(callback: (...args: Args) => ReturnValue) {
  const ref = useRef<typeof callback | typeof errorCallback>(errorCallback);

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  return useCallback((...callbackArgs: Args) => {
    const fn = ref.current;
    return fn(...callbackArgs);
  }, []);
}

export default useStableCallback;
