import { forwardRef, useEffect, useRef } from 'react';
import { Input } from '~/components/granular';
import { useDropdownContext } from '../../Dropdown';

import type { ChangeEvent } from 'react';
import type { DropdownSearchProps } from './DropdownSearch.types';

export const DropdownSearch = forwardRef<HTMLInputElement, DropdownSearchProps>(
  ({ autoFocus = true, searchPlaceholder = 'Search...', onSearchChange }, forwardedRef) => {
    const { searchValue, handleSearchChange } = useDropdownContext();

    const inputRef = useRef<HTMLInputElement | null>(null);

    function handleSearch(event: ChangeEvent<HTMLInputElement>) {
      if (typeof onSearchChange === 'function') {
        onSearchChange(event);
      }
      handleSearchChange(event);
    }

    useEffect(() => {
      if (autoFocus) {
        setTimeout(() => {
          inputRef.current?.focus();
        });
      }
    }, [autoFocus]);

    return (
      <div ref={forwardedRef} className="w-full px-4 py-2">
        <Input
          ref={inputRef}
          placeholder={searchPlaceholder}
          value={searchValue}
          leftIcon="SearchIcon"
          role="search"
          onChange={handleSearch}
        />
      </div>
    );
  },
);
