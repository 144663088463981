import type { GridApi } from 'ag-grid-community';
import { type ReactNode, useMemo, useState } from 'react';
import useStableCallback from '~/hooks/useStableCallback';
import { contextFactory } from '~/utils';

const getOverwriteWarning = (gridId: string) =>
  `⚠️ A GridApi with ID "${gridId}" is already set in GridApiContextProvider.
Give a unique ID to this grid via the \`gridId\` prop to avoid overwriting the GridApi.
`;

export const [GridApiContext, useGridApiContext] = contextFactory<{
  gridApis: Map<string, GridApi>;
  addGridApi: (gridId: string, gridApi: GridApi) => void;
  removeGridApi: (gridId: string) => void;
}>();

const preUpdateGridIdCache = new Set<string>();

export const GridApiContextProvider = ({ children }: { children: ReactNode }) => {
  const [gridApis, setGridApis] = useState<Map<string, GridApi>>(new Map());

  const removeGridApi = useStableCallback((gridId: string) => {
    const newMap = new Map(gridApis);
    newMap.delete(gridId);
    setGridApis(newMap);
  });

  const addGridApi = useStableCallback((gridId: string, gridApi: GridApi) => {
    if (preUpdateGridIdCache.has(gridId) || gridApis.has(gridId)) {
      console.warn(getOverwriteWarning(gridId));
    }

    /*
      If two grids with the same ID are rendered at the same time, the `setGridApis` state
      updates will be batched and the warning above won't trigger.

      By synchronously adding the IDs to this cache, we can check if there are conflicts
      before the state update.

      The cache is cleared whenever the `gridApis` state is updated and the below useMemo is
      invalidated.
    */
    preUpdateGridIdCache.add(gridId);

    setGridApis(new Map(gridApis).set(gridId, gridApi));
  });

  const value = useMemo(() => {
    preUpdateGridIdCache.clear();
    return { gridApis, addGridApi, removeGridApi };
  }, [gridApis]);

  return <GridApiContext.Provider value={value}>{children}</GridApiContext.Provider>;
};
