import { setDefaultOptions } from 'date-fns';
import * as DateFnsLocales from 'date-fns/locale';
import { useCallback, useEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useLocalStorage } from 'usehooks-ts';
import { getCookie, setCookie } from '~/utils/cookies';
import { flattenKeysToIcuPlural } from '~/utils/json';

import de from './de.json';
import en from './en.json';
import fr from './fr.json';
import it from './it.json';
import ja from './ja.json';
import zhCN from './zh_CN.json';

export const localeCode = ['de', 'en-GB', 'fr', 'it', 'zh-CN', 'ja'] as const;

export type LocaleCode = (typeof localeCode)[number];

export type LocaleOptions = {
  id: LocaleCode;
  value: LocaleCode;
  label: string;
};

export const localeOptions: Array<LocaleOptions> = [
  {
    id: 'de',
    value: 'de',
    label: 'Deutsch',
  },
  {
    id: 'en-GB',
    value: 'en-GB',
    label: 'English',
  },
  {
    id: 'fr',
    value: 'fr',
    label: 'Français',
  },
  {
    id: 'it',
    value: 'it',
    label: 'Italiano',
  },
  {
    id: 'zh-CN',
    value: 'zh-CN',
    label: '简体中文',
  },
  {
    id: 'ja',
    value: 'ja',
    label: '日本語',
  },
];

export type Locales = {
  [key: string]: {
    locale: LocaleCode;
    name: string;
    messages: Record<string, string>;
  };
};

export const DEFAULT_LOCALE = 'en-GB';

export const LOCALES: Locales = {
  'en-GB': {
    locale: 'en-GB',
    name: 'English',
    messages: flattenKeysToIcuPlural(en),
  },
  fr: {
    locale: 'fr',
    name: 'Français',
    messages: flattenKeysToIcuPlural(fr),
  },
  it: {
    locale: 'it',
    name: 'Italiano',
    messages: flattenKeysToIcuPlural(it),
  },
  de: {
    locale: 'de',
    name: 'Deutsch',
    messages: flattenKeysToIcuPlural(de),
  },
  'zh-CN': {
    locale: 'zh-CN',
    name: '简体中文',
    messages: flattenKeysToIcuPlural(zhCN),
  },
  ja: {
    locale: 'ja',
    name: '日本語',
    messages: flattenKeysToIcuPlural(ja),
  },
} as const;

export function useLocales() {
  const language = getCookie('site_language') as LocaleCode | undefined;
  const [locale, setLocale] = useLocalStorage('LOCALE', language || DEFAULT_LOCALE);

  const messages = useMemo(() => LOCALES[locale].messages, [locale]);

  useEffect(() => {
    setLocale(language || DEFAULT_LOCALE);
  }, [setLocale, language]);

  const setDateLocale = useCallback(
    (localeCode: string = locale) => {
      const translationsKey = localeCode.replace('-', '') as keyof typeof DateFnsLocales;
      setDefaultOptions({ locale: DateFnsLocales[translationsKey] });
    },
    [locale],
  );

  const updateLocale = useCallback(
    (locale: LocaleCode) => {
      setLocale(locale);
      setDateLocale(locale);
      setCookie('site_language', locale);
    },
    [setLocale, setDateLocale],
  );

  return { locale, messages, updateLocale, setDateLocale };
}

export function WithIntlProvider({ children }: { children: React.ReactNode }) {
  const { locale, messages } = useLocales();

  return (
    <IntlProvider defaultLocale={DEFAULT_LOCALE} locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
